import React from "react"
import { Link } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import ContactForm from "../components/ContactForm"
import { Seo } from "../components/seo"
const ContactUs = () => {
  return (
    <>
      <section className="container contact-us">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="contact-us__responsible-for-company">
              <SectionTitle>お問い合わせ</SectionTitle>
              <hr className="u-line-bottom" />
              <p>
                具体的なお仕事のご紹介をご希望の際は、
                <Link to="/process">ご紹介の流れ</Link>
                へお進みください。
              </p>
              <ContactForm />
            </div>
            <Contact lang={"ja"} />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default ContactUs

export const Head = () => {
  return (
    <Seo
      title="お問い合わせ"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/contact-us`}
    />
  )
}
