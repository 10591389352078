import React from "react"
import { ContactBox } from "../components/styled"
import { languageData } from "../translate/traduction"
const Contact = ({ lang }) => {
  return (
    <ContactBox>
      <p>
        {lang === "es" || lang === "en" ? (
          ""
        ) : (
          <span>メキシコ求人情報とメキシコ就職・転職サポート</span>
        )}

        <a href="tel: +52-449-107-5308" target="_blank" rel="noreferrer">
          TEL +52-449-107-5308
        </a>
      </p>
      <a
        className="btn-contact"
        href={languageData[lang].contact.link}
        target="_blank"
        rel="noreferrer"
      >
        {languageData[lang].contact.button}
      </a>
    </ContactBox>
  )
}

export default Contact
