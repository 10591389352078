// Language for search component
export const languageData = {
  ja: {
    seo: "メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】",
    category: "詳細検索",
    placeholder: "検索",
    search: "検索",
    mexico: "メキシコ",
    jobSearchTitle: "メキシコ求人・就職・転職情報",
    tag: "検索条件",
    qResults1: "検索条件に合う求人は ",
    qResults2: " 件ありました。",
    noResults: "結果がありません",
    modal: {
      jobType: "職種で探す",
      checkAll: "全て選択",
      jobs: {
        "通訳・翻訳": "通訳・翻訳",
        営業: "営業",
        "事務・間接部門・アシスタント": "事務・間接部門・アシスタント",
        "会計・経理": "会計・経理",
        "物流・購買・貿易・運輸": "物流・購買・貿易・運輸",
        "技術職（製造・機械・電気系）": "技術職（製造・機械・電気系）",
        "技術職（SE・ソフトウェア関連）": "技術職（SE・ソフトウェア関連）",
        "技術職（建築・建設・土木）": "技術職（建築・建設・土木）",
        "技術系（その他）": "技術系（その他）",
        その他: "その他",
      },
      location: "地名で探す",
      checkAllLocations: "全て選択",
      locations: {
        "メキシコ連邦区（メキシコシティ）": "メキシコ連邦区（メキシコシティ）",
        グアナファト州: "グアナファト州",
        セラヤ: "セラヤ",
        イラプアト: "イラプアト",
        レオン: "レオン",
        "アパセオ・エル・グランデ": "アパセオ・エル・グランデ",
        シラオ: "シラオ",
        サラマンカ: "サラマンカ",
        アバソロ: "アバソロ",
        アグアスカリエンテス州: "アグアスカリエンテス州",
        ケレタロ州: "ケレタロ州",
        "サン・ルイス・ポトシ州": "サン・ルイス・ポトシ州",
        ハリスコ州: "ハリスコ州",
        ラゴスデモレノ: "ラゴスデモレノ",
        グアダラハラ: "グアダラハラ",
        ヌエボレオン州: "ヌエボレオン州",
        モンテレイ: "モンテレイ",
        その他: "その他",
      },
      locationsSub: {
        "メキシコ連邦区（メキシコシティ）":
          "メキシコ連邦区（メキシコシティ）",
        グアナファト州: "グアナファト州",
        "グアナファト州（セラヤ）": "セラヤ",
        "グアナファト州（イラプアト）": "イラプアト",
        "グアナファト州（レオン）": "レオン",
        "グアナファト州（アパセオ・エル・グランデ）": "アパセオ・エル・グランデ",
        "グアナファト州（シラオ）": "シラオ",
        "グアナファト州（サラマンカ）": "サラマンカ",
        "グアナファト州（アバソロ）": "アバソロ",
        アグアスカリエンテス州: "アグアスカリエンテス州",
        ケレタロ州: "ケレタロ州",
        "サン・ルイス・ポトシ州": "サン・ルイス・ポトシ州",
        ハリスコ州: "ハリスコ州",
        "ハリスコ州（ラゴスデモレノ）": "ラゴスデモレノ",
        "ハリスコ州（グアダラハラ）": "グアダラハラ",
        ヌエボレオン州: "ヌエボレオン州",
        "ヌエボレオン州（モンテレイ）": "モンテレイ",
        その他: "その他",
      },
      condition: "メリットで探す",
      conditions: {
        "西語日常会話～OK": "西語日常会話～OK",
        "西語初級～OK": "西語初級～OK",
        英語のみOK: "英語のみOK",
        未経験歓迎: "未経験歓迎",
        社会人経験者優遇: "社会人経験者優遇",
        "管理職・マネジメント経験者優遇": "管理職・マネジメント経験者優遇",
        時短勤務OK: "時短勤務OK",
        短期勤務: "短期勤務",
        完全週休二日制: "完全週休二日制",
        キャリアパス有: "キャリアパス有",
        女性活躍中: "女性活躍中",
        "教育・研修制度有": "教育・研修制度有",
        "新卒・第二新卒歓迎": "新卒・第二新卒歓迎",
        "": "シニア層歓迎",
        福利厚生充実: "福利厚生充実",
        保険充実: "保険充実",
        社用車貸与有: "社用車貸与有",
        ビザ取得サポート有: "ビザ取得サポート有",
        メキシコ在住者優遇: "メキシコ在住者優遇",
      },
    },
    jobCard: {
      title: "職種",
      location: "勤務地",
      requirements: "必須要件",
      salary: "給与(額面)",
      company: "会社概要",
      details: "詳細を確認",
      free: "今すぐ無料相談",
    },
    contact: {
      title: "メキシコ求人情報とメキシコ就職・転職サポート",
      button: "その他お問い合わせはこちらへ",
      link:"/contact-us"
    },
  },
  en: {
    seo:
      "Job offers/career changes/jobs information in Mexico [QUICK GLOBAL MEXICO]",
    category: "Categories",
    placeholder: "Searching",
    search: "Search",
    mexico: "Mexico",
    jobSearchTitle: "Mexico Jobs, Employment and Career Change Information",
    tag: "Tags",
    qResults11: "There is ",
    qResults21: " job that matches your search criteria.",
    qResults12: "There are ",
    qResults22: " jobs that match your search criteria.",
    noResults: "No Results",
    modal: {
      jobType: "Search by Job Title",
      checkAll: "Select All",
      jobs: {
        "通訳・翻訳": "Interpreter/Translator",
        営業: "Sales",
        "事務・間接部門・アシスタント": "Administration/Support Roles",
        "会計・経理": "Accounting/Finance",
        "物流・購買・貿易・運輸": "Logistics/Procurement/Trade/Transportation",
        "技術職（製造・機械・電気系）":
          "Technical Roles (Manufacturing/Machinery/Electrical)",
        "技術職（SE・ソフトウェア関連）":
          "Technical Roles (SE/Software Development)",
        "技術職（建築・建設・土木）":
          "Technical Roles (Architecture/Construction/Civil Engineering)",
        "技術系（その他）": "Other Technical Roles",
        その他: "Other",
      },
      location: "Search by Location",
      checkAllLocations: "Select All",
      locations: {
        "メキシコ連邦区（メキシコシティ）":
          "Mexico City (Mexico Federal District)",
        グアナファト州: "Guanajuato",
        セラヤ: "Celaya",
        イラプアト: "Irapuato",
        レオン: "Leon",
        "アパセオ・エル・グランデ": "Apaseo el Grande",
        シラオ: "Silao",
        サラマンカ: "Salamanca",
        アバソロ: "Abasolo",
        アグアスカリエンテス州: "Aguascalientes",
        ケレタロ州: "Queretaro",
        "サン・ルイス・ポトシ州": "San Luis Potosi",
        ハリスコ州: "Jalisco",
        ラゴスデモレノ: "Lagos de Moreno",
        グアダラハラ: "Guadalajara",
        ヌエボレオン州: "Nuevo Leon",
        モンテレイ: "Monterrey",
        その他: "Other",
      },
      locationsSub: {
        "メキシコ連邦区（メキシコシティ）":
          "Mexico City (Mexico Federal District)",
        グアナファト州: "Guanajuato",
        "グアナファト州（セラヤ）": "Celaya",
        "グアナファト州（イラプアト）": "Irapuato",
        "グアナファト州（レオン）": "Leon",
        "グアナファト州（アパセオ・エル・グランデ）": "Apaseo el Grande",
        "グアナファト州（シラオ）": "Silao",
        "グアナファト州（サラマンカ）": "Salamanca",
        "グアナファト州（アバソロ）": "Abasolo",
        アグアスカリエンテス州: "Aguascalientes",
        ケレタロ州: "Queretaro",
        "サン・ルイス・ポトシ州": "San Luis Potosi",
        ハリスコ州: "Jalisco",
        "ハリスコ州（ラゴスデモレノ）": "Lagos de Moreno",
        "ハリスコ州（グアダラハラ）": "Guadalajara",
        ヌエボレオン州: "Nuevo Leon",
        "ヌエボレオン州（モンテレイ）": "Monterrey",
        その他: "Other",
      },
      condition: "Search by Benefits",
      conditions: {
        "西語日常会話～OK": "Basic Spanish Skills Accepted",
        "西語初級～OK": "Beginner-Level Spanish Accepted",
        英語のみOK: "English only Accepted",
        未経験歓迎: "No experience required",
        社会人経験者優遇: "Preference for Experienced Professionals",
        "管理職・マネジメント経験者優遇":
          "Preference for Managerial/Leadership Experience",
        時短勤務OK: "Part-time work Accepted",
        短期勤務: "Short-Term Employment",
        完全週休二日制: "Full Two-Day Weekends Off",
        キャリアパス有: "Career Advancement Opportunities",
        女性活躍中: "Women in Leadership Roles",
        "教育・研修制度有": "Training and Development Programs Available",
        "新卒・第二新卒歓迎": "New Graduates and Recent Graduates Welcome",
        シニア層歓迎: "Seniors welcome",
        福利厚生充実: "Comprehensive Benefits",
        保険充実: "Excellent Insurance Coverage",
        社用車貸与有: "Company car provided",
        ビザ取得サポート有: "Visa acquisition support available",
        メキシコ在住者優遇: "Preference for Residents in Mexico",
      },
    },
    jobCard: {
      title: "Job Title",
      location: "Location",
      requirements: "Requirements",
      salary: "Salary (Gross)",
      company: "Company Overview",
      details: "View Details",
      free: "Get a Free Consultation",
    },
    contact: {
      title: "Mexico Job Listings and Career Support",
      button: "For other inquiries, click here",
      link:"/en/job-form"
    },
  },
  es: {
    seo:
      "Ofertas de empleo/cambio de carrera/información de empleo en México [QUICK GLOBAL MEXICO]",
    category: "Categorias",
    placeholder: "Busqueda",
    search: "Buscar",
    mexico: "México",
    jobSearchTitle:
      "Información sobre reclutamiento/empleo/cambio de carrera en México",
    tag: "Etiquetas",
    qResults1: "Hay ",
    qResults2: " empleo(s) que coincidieron con su criterio de búsqueda.",
    noResults: "Sin Resultados",
    modal: {
      jobType: "Título de empleo",
      checkAll: "Seleccionar Todo",
      jobs: {
        "通訳・翻訳": "Interpretación/Traducción",
        営業: "Ventas",
        "事務・間接部門・アシスタント": "Administrativo/Soporte",
        "会計・経理": "Contabilidad",
        "物流・購買・貿易・運輸": "Logística/Compras/Comercio/Transporte",
        "技術職（製造・機械・電気系）":
          "Trabajo técnico (fabricación/mecánica/eléctrica)",
        "技術職（SE・ソフトウェア関連）":
          "Trabajo técnico (SE/relacionado con software)",
        "技術職（建築・建設・土木）":
          "Trabajo técnico (arquitectura/construcción/ingeniería civil)",
        "技術系（その他）": "Trabajo técnico (otro)",
        その他: "Otro",
      },
      location: "Ciudades",
      checkAllLocations: "Seleccionar Todas",
      locations: {
        "メキシコ連邦区（メキシコシティ）":
          "Distrito Federal (Ciudad de México)",
        グアナファト州: "Guanajuato",
        セラヤ: "Celaya",
        イラプアト: "Irapuato",
        レオン: "León",
        "アパセオ・エル・グランデ": "Apaseo el Grande",
        シラオ: "Silao",
        サラマンカ: "Salamanca",
        アバソロ: "Abasolo",
        アグアスカリエンテス州: "Aguascalientes",
        ケレタロ州: "Querétaro",
        "サン・ルイス・ポトシ州": "San Luis Potosí",
        ハリスコ州: "Jalisco",
        ラゴスデモレノ: "Lagos de Moreno",
        グアダラハラ: "Guadalajara",
        ヌエボレオン州: "Nuevo León",
        モンテレイ: "Monterrey",
        その他: "Otro",
      },
      locationsSub: {
        "メキシコ連邦区（メキシコシティ）":
          "Distrito Federal (Ciudad de México)",
        グアナファト州: "Guanajuato",
        "グアナファト州（セラヤ）": "Celaya",
        "グアナファト州（イラプアト）": "Irapuato",
        "グアナファト州（レオン）": "León",
        "グアナファト州（アパセオ・エル・グランデ）": "Apaseo el Grande",
        "グアナファト州（シラオ）": "Silao",
        "グアナファト州（サラマンカ）": "Salamanca",
        "グアナファト州（アバソロ）": "Abasolo",
        アグアスカリエンテス州: "Aguascalientes",
        ケレタロ州: "Querétaro",
        "サン・ルイス・ポトシ州": "San Luis Potosí",
        ハリスコ州: "Jalisco",
        "ハリスコ州（ラゴスデモレノ）": "Lagos de Moreno",
        "ハリスコ州（グアダラハラ）": "Guadalajara",
        ヌエボレオン州: "Nuevo León",
        "ヌエボレオン州（モンテレイ）": "Monterrey",
        その他: "Otro",
      },
      condition: "Beneficios",
      conditions: {
        "西語日常会話～OK": "Habilidad básica conversacional en español",
        "西語初級～OK": "Nivel de español principiante",
        英語のみOK: "Solo ingles",
        未経験歓迎: "Sin experiencia necesaria",
        社会人経験者優遇: "Preferencia por profesionales con experiencia",
        "管理職・マネジメント経験者優遇":
          "Preferencia por experiencia gerencial/de liderazgo",
        時短勤務OK: "Trabajo de medio tiempo",
        短期勤務: "Trabajo a corto plazo",
        完全週休二日制: "Sistema completo de cinco días",
        キャリアパス有: "Oportunidades de avance profesiona",
        女性活躍中: "Mujeres en puestos de liderazgo",
        "教育・研修制度有":
          "Programas de capacitación y desarrollo disponibles",
        "新卒・第二新卒歓迎": "Bienvenidos nuevos graduados y recién graduados",
        シニア層歓迎: "Candidatos Senior Bienvenidos",
        福利厚生充実: "Beneficios integrales",
        保険充実: "Exelente cobertura de seguro",
        社用車貸与有: "Se proporciona coche de empresa",
        ビザ取得サポート有: "Soporte para la adquisición de visas",
        メキシコ在住者優遇: "Preferencia por residentes en México",
      },
    },
    jobCard: {
      title: "Industria",
      location: "Lugar de trabajo",
      requirements: "Requisitos indispensables",
      salary: "Sueldo mensual (Bruto)",
      company: "Descripción de la empresa",
      details: "Ver detalles",
      free: "Consulta GRATUITA",
    },
    contact: {
      title: "Ofertas de trabajo y apoyo profesional en México",
      button: "Para otra consulta, haz click aquí",
      link:"/es/job-form"
    },
  },
}
